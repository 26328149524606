import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  translations
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const locales = {
  fi: 'Suomi',
  sv: 'Ruotsi',
  en: 'Englanti'
} as const

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Käännökset">
    <Section>
      <Table
        divider="horizontal"
        responsive
      >
        <TableHeader>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            {Object.keys(locales).map(locale => (
              <TableHeaderCell key={locale}>
                {locales[locale as keyof typeof locales]}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.keys(translations.fi).map(id => (
            <TableRow key={id}>
              <TableCell>
                <Code>{id}</Code>
              </TableCell>
              {Object.keys(locales).map(locale => (
                <TableCell key={locale}>
                  {
                    translations[locale as keyof typeof locales][
                      id as keyof typeof translations.fi
                    ]
                  }
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  </Content>
)

export default Page
